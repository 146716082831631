:root {
  --main-color: #000;
  --main-color-light: #fff;
  --main-bg: #fff;
  --white-color: #fff;
  --primary-bg: #111;
  --secondary-bg: #FAFAFA;
  --tertiary-bg: #fff;
  --quarter-bg: #181818;
  --fifth-bg: #1F1F1F;
  --sixth-bg: #000;
  --seventh-bg: #EFEFEF;
  // --eighth-bg: #415057;

  --accent-color: #c8cfff;
  --accent2-color: #F64C31;
  --accent3-color: #1E274E;
  --primary-color: #111;

  --secondary-color: #F64C31;
  --tertiary-color: #fff;
  --quarter-color: #DDDDDD;
  --fifth-color: #FAFAFA;
  --sixth-color: #777;
  --seventh-color: #9C9C9C;
  --eighth-color: #979797;
  --ninth-color: #A1A1A1;

  --main-border-color: #EDEFF6;
  --secondary-border-color: #333333;
  --tertiary-border-color: #E1E1E1;

  --primary-font-family: "Outfit", system-ui;

  --button-bg: #3648CE;
  --button-color: #fff;
  --button-bg-hover: #FAFAFA;
  --button-color-hover: #111111;
  --button2-bg: #FAFAFA;
  --button2-color: #111111;
  --button2-bg-hover: #FAFAFA;
  --button2-color-hover: #111111;
  //--button3-bg: #111111;
  --button3-bg: transparent;
  --button3-color: #FAFAFA;
  //--button3-bg-hover: #111111;
  --button3-bg-hover: transparent;
  --button3-color-hover: #9AACFF;

  --main-box-shadow: 0px 0px 1.5rem 0px rgba(34, 34, 34, 0.10);
}
$error: #ff0000;