$site-domain: "";
/*updates*/
.breadcrumbs {
  z-index: 10;
}

$img-path: "#{$site-domain}/Media/Image";
.last-section {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 176px;
    background: url(#{$img-path}/last-section-decor.png) no-repeat 50% 0 / 100% 100%;
  }

  @media only screen and (max-width: 1920px) {
    &:after {
      width: 192rem;
      left: 50%;
      right: auto;
      margin-left: -96rem;
    }
  }
}

.rtl-page .last-section:after {
  transform: scaleX(-1);
}

.about {
  &__heading {
    display: flex;
    background: var(--sixth-bg);
    padding: 80px 0 435px;
    text-align: center;
    position: relative;
    font-size: 16px;
    font-weight: 300;
    color: var(--white-color);
    overflow: hidden;

    &:before,
    &:after {
      content: "";
      position: absolute;
    }

    &:after {
      height: 205px;
      bottom: 0;
      left: 0;
      right: 0;
      background: url(#{$img-path}/about-decor01.png) no-repeat 50% 0 / 100% 100%;
    }

    &:before {
      z-index: 1;
      height: 473px;
      // padding: 0 0 24.6354%;
      max-width: 1920px;
      bottom: -45px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: url(#{$img-path}/about-decor02.png) no-repeat 50% 0 / 100% 100%;
    }

    > img,
    > picture img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .main-holder {
      position: relative;

      > *:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      color: var(--white-color);
      margin-bottom: 30px;
    }

    @media only screen and (max-width: 1920px) {
      padding-bottom: 22.65625%;

      &:before {
        height: auto;
        padding: 0 0 24.6354%;
      }

      &:after {
        height: auto;
        padding: 0 0 10.9375%;
      }
    }

    @media only screen and (max-width: 767px) {
      &:before {
        content: none;
      }

      > img,
      > picture img {
        max-width: none;
        left: -450px;
        width: calc(100% + 900px);
      }
    }

    @media only screen and (max-width: 479px) {
      padding-top: 4rem;
      padding-bottom: 28.125%;
      font-size: 14px;

      h1 {
        font-size: 32px;
        line-height: 35px;
        margin-bottom: 2rem;
      }

    }
  }

  &__intro {
    .txt-h {
      margin: 0 auto;
      max-width: 370px;
      position: relative;
      left: -5px;
      padding-bottom: 40px;

      h2 {
        font-size: 32px;
        line-height: 40px;
        font-weight: 400;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 10px;
      }
    }

    @media only screen and (max-width: 767px) {
      .img-h {
        margin-inline-end: -10px;
        margin-bottom: 30px;
      }

      .txt-h {
        left: 0;

        h2 {
          font-size: 28px;
          line-height: 40px;
        }
      }
    }
  }

  &__timeline {
    background-image: linear-gradient(-270deg, #cf4162, #97334d, #632637, #331820, #000000);
    background: linear-gradient(135deg, #cf4162 0%, #000 80%, #000 100%);

    padding: 50px 85px;
    color: var(--white-color);
    border-radius: 300px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    h2 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 52px;
      color: var(--white-color);
      font-weight: 400;
    }

    .items {
      display: flex;
      margin-bottom: -50px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -6px;
        height: 12px;
        background: #EB314C url(#{$img-path}/about-decor03.png) repeat;
        box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.1)
      }

      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 122px;
        padding: 0 17px;
        gap: 84px;
        font-size: 17px;
        font-weight: 400;
        line-height: normal;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background: #fff;
        }

        &:after {
          content: "";
          position: absolute;
          left: -3px;
          top: -3px;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #fff;
        }

        .year {
          font-size: 42px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }

        p {
          flex: 1;
          margin-bottom: 0;
        }

        &:nth-child(2n) {
          flex-direction: column-reverse;
          margin-top: 0;
          margin-bottom: 122px;
          gap: 109px;

          &:after {
            top: auto;
            bottom: -3px;
          }
        }
      }
    }

    @media only screen and (max-width: 1200px) {
      padding: 50px 25px;
      border-radius: 180px;

      .items {
        .item {
          padding: 0 10px;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 180px;
      padding: 50px 15px 155px;
      background: linear-gradient(-135deg, #cf4162 0%, #000 80%, #000 100%);

      .items {
        flex-direction: column;
        margin-left: -15px;
        margin-right: -15px;

        &:before {
          top: 0;
          bottom: 0;
          margin: 0 auto;
          height: auto;
          width: 12px;
          background: #EB314C url(#{$img-path}/about-decor03-sm.png) repeat;
        }

        .item {
          margin-top: 0;
          flex-direction: row-reverse;
          // min-height: 143px;
          padding: 15px 30px;
          align-items: center;
          gap: 50px;

          > * {
            flex: 1;
          }

          &:before {
            left: 0;
            height: 1px;
            width: calc(100% - 75px);
          }

          &:after {
            left: auto;
            right: 73px;
          }

          .year {
            text-align: center;
          }

          &:nth-child(2n) {
            flex-direction: row;
            margin-bottom: 0;
            gap: 50px;

            &:before {
              left: auto;
              right: 0;
            }

            &:after {
              bottom: auto;
              top: -3px;
              right: auto;
              left: 73px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      border-radius: 180px;

      h2 {
        margin-bottom: 33px;
      }

      .items {
        .item {
          padding: 15px;
          min-height: 143px;
        }
      }
    }
  }

  &__updates {
    padding: 70px 0 240px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: -240px;
      transform: translateX(-50%);
      margin-left: -170px;
      width: 1195px;
      height: 969px;
      background: url(#{$img-path}/about-decor04.png) no-repeat 50% 0 / 100% 100%;

    }

    .main-holder {
      position: relative;
    }

    h2 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 80px;
      font-weight: 400;
    }

    .items-label {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 300;
      line-height: normal;
      gap: 41px;
      margin-bottom: 31px;

      .item {
        display: inline-flex;
        align-items: center;
        gap: 15px;
        color: #1979C3;

        &:before {
          content: "";
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #1979C3;
        }

        &.live {
          color: #EB314C;

          &:before {
            background: #EB314C;
          }
        }
      }
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      max-width: 900px;
      margin: 0 auto;
      position: relative;
      z-index: 1;

      .item {
        position: relative;
        background: #fff;
        border-radius: 10px;
        border: 1px solid rgba(183, 192, 235, 0.30);
        padding: 13px 20px;
        padding-inline-start: 32px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #1979C3;
        text-align: center;
        box-shadow: 0px 4px 6px rgba(25, 121, 195, 0.40);

        &:before {
          content: "";
          position: absolute;
          top: 12px;
          left: 15px;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #1979C3;
        }

        &.live {
          color: #EB314C;

          &:before {
            background: #EB314C;
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {

      h2 {
        font-weight: 400;
        margin-bottom: 30px;
      }

      .items-label {
        margin-bottom: 30px;
      }

      .items {
        gap: 9px 10px;
      }
    }

    @media only screen and (max-width: 479px) {
      padding-top: 55px;
      padding-bottom: 35px;
    }
  }
}

.rtl-page {

  .about__heading > img,
  .about__heading > picture img {
    transform: scaleX(-1);
  }

  .about__intro .txt-h {
    left: auto;
    right: -5px;

    @media only screen and (max-width: 767px) {
      right: 0;
    }
  }

  .about__timeline {
    background: linear-gradient(-135deg, #cf4162 0%, #000 80%, #000 100%);

    .items .item {
      &:before {
        left: auto;
        right: 0;
      }

      &:after {
        left: auto;
        right: -3px;
      }
    }

    @media only screen and (max-width: 991px) {
      background: linear-gradient(135deg, #cf4162 0%, #000 80%, #000 100%);

      .items .item {
        &:before {
          left: auto;
          right: 0;
        }

        &:after {
          right: auto;
          left: 73px;
        }

        &:nth-child(2n) {
          &:before {
            right: auto;
            left: 0;
          }

          &:after {
            left: auto;
            right: 73px;
          }
        }
      }
    }
  }

  .about__updates .items .item:before {
    left: auto;
    right: 15px;
  }
}


.fees {
  &__heading {
    display: flex;
    // background: var(--sixth-bg);
    padding: 80px 0 305px;
    padding-bottom: 17.4479vw;
    text-align: center;
    position: relative;
    font-size: 16px;
    font-weight: 300;
    color: var(--white-color);
    // min-height: 503px;
    // min-height: 26.1979vw;

    > img,
    > picture img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .main-holder {
      position: relative;

      > *:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      color: var(--white-color);
      margin-bottom: 30px;
    }

    @media only screen and (max-width: 1920px) {
      padding-bottom: 305px;

      > img,
      > picture img {
        object-fit: cover;
      }
    }

    @media only screen and (max-width: 767px) {
      padding-bottom: 63.4375vw;
    }

    @media only screen and (max-width: 479px) {
      padding-top: 4rem;
      font-size: 14px;

      h1 {
        font-size: 32px;
        line-height: 35px;
        margin-bottom: 2rem;
      }

    }
  }

  &__accounts {
    margin-bottom: 30px;

    input[type=radio] {
      opacity: 0;
      position: absolute;
      visibility: hidden;
    }

    label {
      float: left;
      width: 50%;
      padding: 15px;
      display: block;
      position: relative;
      border-radius: 28px 28px 0 0;
      cursor: pointer;

      &:before,
      &:after {
        content: "";
        position: absolute;
        opacity: 0;
      }

      &:before {
        right: -25px;
        bottom: 25px;
        width: 25px;
        height: 25px;
        background: url(#{$img-path}/fees-decor01.png) no-repeat 50% 0 / 100% 100%;
      }

      &:after {
        left: 0;
        right: -25px;
        bottom: -25px;
        height: 50px;
        background: #fff;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        text-align: center;
        border: 1px solid #000;
        border-radius: 40px;
        font-size: 18px;
        font-weight: 300;
        line-height: 20px;
        padding: 3px 70px;
        position: relative;
        transition: all .3s;
        z-index: 1;

        &:before {
          position: absolute;
          top: 7px;
          right: 20px;
          font-family: 'icomoon';
          content: "\34";
          font-size: 45px;
          line-height: 6px;
          color: #00AD7D;
        }
      }

      &:hover {
        span {
          color: #00AD7D;
          border-color: #00AD7D;
        }
      }

      &[for=fees-account-business] {
        &:before {
          right: auto;
          left: -25px;
          transform: scaleX(-1);
        }

        &:after {
          left: -25px;
          right: 0;
        }

        span:before {
          top: auto;
          right: auto;
          bottom: 7px;
          left: 20px;
          transform: scaleX(-1) scaleY(-1);
        }
      }
    }

    input[type=radio]:checked + label {
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      margin-bottom: -25px;
      padding-bottom: 40px;

      &:before,
      &:after {
        opacity: 1;
      }

      span {
        color: #fff;
        background: #00AD7D;
        border-color: #00AD7D;
        font-weight: 500;

        &:before {
          color: #fff;
        }
      }
    }

    &-hold {
      width: 100%;
      clear: both;
      background: #fff;
      border-radius: 28px;
      padding: 36px 81px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    }

    &-items {
      position: relative;
      margin-bottom: 90px;
      margin-top: 10px;
    }

    &-heading {
      display: flex;
      align-items: center;
      gap: 35px;
      margin-bottom: 0;
      padding: 0 20px;

      h2 {
        font-size: 40px;
        line-height: normal;
        font-weight: 600;
        margin-bottom: 0;
      }

      img {
        width: 100px;
        height: 100px;
      }
    }

    &-data {
      margin-bottom: 40px;

      dl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        gap: 0;

        dt {
          font-size: 22px;
          line-height: normal;
          font-weight: 300;
        }

        dd {
          font-size: 18px;
          line-height: normal;
          font-weight: 300;
          color: #115F3C;
          text-align: end;
        }

        &:nth-child(2n) {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }
    }

    &-item:nth-child(1) {
      h2 {
        color: #58121C;
      }

      dl:nth-child(2n) {
        background: #DBEFED;
      }
    }

    &-item:nth-child(2) {
      h2 {
        color: #062D1B;
      }

      dl:nth-child(2n) {
        background: #F2E8E6;
      }
    }

    &-item:nth-child(3) {
      h2 {
        color: #1A1D52;
      }

      dl:nth-child(2n) {
        background: #E3E4F0;
      }
    }

    .btn-hold {
      .button {
        min-width: 378px;
      }
    }

    .business-content {
      display: none;
    }

    #fees-account-business:checked ~ .fees__accounts-hold .personal-content {
      display: none;
    }

    #fees-account-business:checked ~ .fees__accounts-hold .business-content {
      display: block;
    }

    @media only screen and (max-width: 991px) {
      &-hold {
        padding: 36px 31px;
      }
    }

    @media only screen and (max-width: 767px) {
      label {
        padding: 5px;

        span {
          border-radius: 25px;
          padding: 8px 25px 18px;
          font-size: 14px;
          line-height: 18px;
          height: 55px;

          &:before {
            top: auto !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 7px !important;
          }
        }
      }

      input[type=radio]:checked + label {
        // margin-bottom: -25px;
        padding-bottom: 30px;
      }

      &-hold {
        padding: 36px 15px;
      }

      &-heading {
        gap: 10px;

        h2 {
          font-size: 30px;
        }

        img {
          width: 75px;
          height: 75px;
        }
      }

      &-data {
        dl {
          dt {
            font-size: 17px;
          }

          dd {
            font-size: 15px;
          }
        }
      }
    }

    @media only screen and (max-width: 479px) {
      margin-bottom: 15px;
      &-hold {
        padding: 5px;
      }

      &-items {
        margin: 20px 0 0;
      }

      &-heading {
        gap: 10px;
        padding: 0 7px;

        h2 {
          font-size: 18px;
        }

        img {
          width: 50px;
          height: 50px;
        }
      }

      &-data {
        margin: 0 0 25px;

        dl {
          padding: 6px 7px;

          dt {
            font-size: 12px;
          }

          dd {
            font-size: 10px;
          }

          &:nth-child(2n) {
            padding: 3px 7px;
          }
        }
      }

      .btn-hold {
        padding-top: 5px;

        .button {
          min-width: auto;
        }
      }
    }


  }
}

.rtl-page {

  .fees__heading > img,
  .fees__heading > picture img {
    transform: scaleX(-1);
  }
}

.section70 {
  background: linear-gradient(200deg, #1979C3 23.38%, #EB314C 81.73%);
  position: relative;
  padding: 114px 0 67px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 176px;
    background: url(#{$img-path}/section70-decor01.png) no-repeat 50% 0 / 100% 100%;
  }

  @media only screen and (max-width: 1920px) {
    &:before {
      width: 192rem;
      left: 50%;
      right: auto;
      margin-left: -96rem;
    }
  }

  .txt-h {
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #fff;
    position: relative;
    z-index: 1;

    h2 {
      color: #FFF;
      font-size: 50px;
      font-weight: 600;
      line-height: 65px;
      margin-bottom: 30px;
    }

    .btn-hold {
      padding-top: 30px;
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 114px 0 107px;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 27px;

    .img-h {
      margin: 0 5px 0 28px;
    }

    .txt-h {
      font-size: 22px;

      h2 {
        font-size: 28px;
        line-height: normal;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    margin-top: 0;
    padding-bottom: 12rem;
    &:before {
      width: 120rem;
    }
    .txt-h {
      padding-top: 32px;

      h2 {
        margin-bottom: 18px;
      }

      .btn-hold {
        padding-top: 10px;
      }
    }
  }
}

.rtl-page .fees__accounts label {
  float: right;
}

.rtl-page .fees__accounts label:before {
  right: auto;
  left: -25px;
  transform: scaleX(-1);
}

.rtl-page .fees__accounts label:after {
  right: 0;
  left: -25px;
}

.rtl-page .fees__accounts label[for=fees-account-business]:before {
  left: auto;
  right: -25px;
  transform: scaleX(1);
}

.rtl-page .fees__accounts label[for=fees-account-business]:after {
  left: 0;
  right: -25px;
}

.rtl-page .fees__accounts label span:before {
  right: auto;
  left: 20px;
  transform: scaleX(-1);
}

.rtl-page .fees__accounts label[for=fees-account-business] span:before {
  transform: scaleX(1);
  left: auto;
  right: 20px;
}

.rtl-page .section70 {
  background: linear-gradient(-200deg, #1979C3 23.38%, #EB314C 81.73%);
}

.rtl-page .section70 .img-h,
.rtl-page .section70:before {
  transform: scaleX(-1);
}