/* buttons */
.button {
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 3.5rem;
  gap: 1rem;
  min-height: 5rem;
  text-decoration: none;
  text-align: center;
  border-radius: 50px;
  background: #2A4CE9;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  border: 1px solid transparent;
  min-width: 220px;
  font-weight: 400;

  *[class*=icon-] {
    font-size: 1.2rem;
    padding-left: 1.5rem;
  }

  &:hover,
  &.active {
    background: #7088E8;
    border-color: #7088E8;
    text-decoration: none;
    color: #fff;
  }

  &.button2 {
    background: linear-gradient(270deg, #000 0%, #000 100%);
    border: 0;

    &:hover,
    &.active {
      background: linear-gradient(270deg, #000 0%, #E43551 100%);
      border-color: transparent;
    }
  }

  &.button3 {
    background: #00AD7D;
    border-color: transparent;

    &:hover,
    &.active {
      background: #66C5AB;
      border-color: #66C5AB;
    }
  }

  &.button4 {
    background: #C8CFFF;
    color: #000;
    border-color: transparent;

    &:hover {
      background: #fff;
      color: #000;
      border-color: #fff;
    }
  }

  &.button5 {
    background: #fff;
    color: #000;
    border-color: transparent;

    &:hover {
      background: #000;
      color: #fff;
      border-color: #000;
    }
  }

  &.xs-size {
    min-width: 81px;
    min-height: 3rem;
    font-size: 16px;
    padding: 1rem;
    line-height: 8px;
  }

  &.xl-size {
    min-width: 273px;
  }

  &.bordered {
    color: #000;
    border-color: #000;
    background: #fff;

    &:hover {
      border-color: #000;
      background: #000;
      color: #fff;
    }
  }

  &.bordered-blue {
    border: 5px solid #fff;
  }


  @media only screen and (max-width: 480px) {
    font-size: 18px;
    min-width: 220px;
    width: 100%;
    padding: 1rem 1.5rem;
    &.xs-size {
      width: auto;
    }
    &.bordered-blue {
      border: 3px solid #fff;
    }
  }
}


.text-link {
  display: inline-flex;
  align-items: center;
  gap: 1rem;

  *[class*=icon-] {
    font-size: 1rem;
  }

  &:hover {
    text-decoration: none;
    //color: var(--tertiary-color);
  }
}

.more {
  display: inline-flex;
  align-items: center;
  gap: 1.2rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.2rem;
  text-transform: capitalize;

  *[class*="icon-"] {
    font-size: 1rem;
    font-weight: 700;
  }

  &:hover {
    text-decoration: none;
  }
}

.btn-block {
  display: flex;
  width: 100%;
}

.centered-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

@media (max-width: 575px) {
  .btn-block-xs {
    width: 100%;
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .btn-block-sm {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn-block-md {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn-block-lg {
    width: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .btn-block-xl {
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .btn-block-xxl {
    width: 100%;
  }
}