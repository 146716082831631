
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?89z6j7');
  src: url('../fonts/icomoon.eot?89z6j7#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?89z6j7') format('truetype'),
  url('../fonts/icomoon.woff?89z6j7') format('woff'),
  url('../fonts/icomoon.svg?89z6j7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  // font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"] > span,
[class*=" icon-"] > span {
  display: none;
}

.icon-arrD:before {
  content: "\e90d";
}
.icon-brand-fb:before {
  content: "\41";
}
.icon-brand-x:before {
  content: "\42";
}
.icon-brand-ig:before {
  content: "\43";
}
.icon-brand-in:before {
  content: "\44";
}
.icon-brand-yt:before {
  content: "\45";
}
.icon-brand-wa:before {
  content: "\46";
}
.icon-menu-burger:before {
  content: "\e900";
}
.icon-profile:before {
  content: "\e901";
}
.icon-menu-arrow:before {
  content: "\e902";
}
.icon-list-bullet:before {
  content: "\e903";
}
.icon-cross:before {
  content: "\e904";
}
.icon-plus:before {
  content: "\e905";
}
.icon-minus:before {
  content: "\e906";
}
.icon-link:before {
  content: "\e907";
}
.icon-slider-arrow:before {
  content: "\e908";
}
.icon-pagination-arrow:before {
  content: "\e909";
}
.icon-pagination-arrows:before {
  content: "\e90a";
}
.icon-phone:before {
  content: "\e90b";
}
.icon-email:before {
  content: "\e90c";
}
.icon-play:before {
  content: "\e91a";
}
.icon-arrSliderR:before {
  content: "\e91b";
}
.icon-arrSliderL:before {
  content: "\e91c";
}
.icon-arrSliderL1:before {
  content: "\e922";
}
.icon-arrSliderR1:before {
  content: "\e923";
}
.icon-arr-sliderR:before {
  content: "\e924";
}
.icon-arr-sliderL:before {
  content: "\e925";
}
.icon-check:before {
  content: "\e927";
}
.icon-close:before {
  content: "\e92b";
}
.icon-slL:before {
  content: "\e92e";
}
.icon-slR:before {
  content: "\e92f";
}

.icon-bookmark:before {
  content: "\31";
}

.icon-caret:before {
  content: "\32";
}

.icon-arrow:before {
  content: "\33";
}

.icon-arrow-long:before {
  content: "\34";
}

.rtl-page .icon-link:before {
  display: inline-block;
  transform: scaleX(-1);
}

.icon-arrL:before {
  content: "\e90e";
}
.icon-eyeC:before {
  content: "\e90f";
}
.icon-eye:before {
  content: "\e910";
}
